import _ from "lodash";
import Promise from "bluebird";
import dateFormat from "dateformat";
import BaseApiModel from "scripts/models/baseApiModel";
import HybridModelHoc from "scripts/hoc/hybridModelHoc";
import Util from "scripts/utils/util";
import inject from "scripts/ioc/inject";

import fallBackImage from "images/fallbacks/fallback_469_625.jpg";

class ContentModel extends HybridModelHoc(BaseApiModel) {
  constructor(
    attrs,
    options,
    bookshelfService = inject("bookshelfService"),
    securityService = inject("securityService"),
  ) {
    super(attrs, options);
    this.bookshelfService = bookshelfService;
    this.securityService = securityService;
  }

  urlRoot() {
    return `${this.apiUrlRoot}/content/`;
  }

  parse(response) {
    if ("contributors" in response) {
      // Overwrite contributors with ordered contributors having formatted name and role
      response.contributors = Util.getFormattedContributors(response.contributors, true);

      // Add comma separated contributors
      response.contributorList = response.contributors
        .map(contributor => `${contributor.name} (${contributor.role})`)
        .join(", ");
    }

    // Strip language for "No linguistic content"
    if (response.languageIso639_2 === "zxx") {
      response.language = "";
    }

    // Format pub date with year only
    if (response.publicationDate) {
      response.publicationDate = dateFormat(response.publicationDate, "UTC:yyyy");
    }

    // Add thumbnail fallback image
    response.thumbnailFallbackUrl = fallBackImage;

    // Make it easy to check if EPUB
    response.isEpub = response.mimeType && response.mimeType.indexOf("epub") !== -1;

    // filter badge types
    response.whiteMediaBadges = _.filter(response.mediaBadges, {
      type: "white",
    });
    response.blackMediaBadges = _.filter(response.mediaBadges, {
      type: "black",
    });

    // response.awardMediaBadges = _.filter(response.mediaBadges, {
    //   type: "award",
    // });
    response.awardMediaBadges = this.getAwards(response.mediaBadges);

    if (response.productIdentifiers !== undefined) {
      response.isbns = response.productIdentifiers
        .filter(_ => _.type.startsWith("ISBN"))
        .map(_ => _.value)
        .join(", ");
    }

    // create a unique-ish label for aria-label tags
    response.ariaLabel = response.subtitle ? response.title + " - " + response.subtitle : response.title;

    response.typeDisplay = response.type ? response.type.replace("_", " ") : "";

    return response;
  }

  isBookshelfEligible() {
    return _.includes(["BOOK", "ARTICLE", "OTHER_DOCUMENT", "IMAGE", "CHAPTER"], this.get("type"));
  }

  fetchHybridData() {
    if (this.isBookshelfEligible() && this.bookshelfService.isAvailable()) {
      return this.getBookshelfData().then(bookshelfData => this.addImageUrls(bookshelfData));
    }
  }

  getBookshelfData() {
    const organizationId = this.securityService.getUser().getActiveOrganizationId();
    return this.bookshelfService.getBookshelfData(this.get("id"), organizationId);
  }

  addImageUrls(bookshelfData) {
    if (bookshelfData.onBookshelf) {
      const organizationId = this.securityService.getUser().getActiveOrganizationId();
      return Promise.all([
        this.bookshelfService
          .getLocalContentFileUrlForRemoteUrl(this.get("thumbnailUrl"), this.get("id"), organizationId, "data")
          .catch(() => {
            //console.log('Failed to find thumbnail url on filesystem: ' + this.get('thumbnailUrl'));
            return this.get("thumbnailUrl");
          }),
        this.bookshelfService
          .getLocalContentFileUrlForRemoteUrl(this.get("imageUrl"), this.get("id"), organizationId, "data")
          .catch(() => {
            //console.log('Failed to find image url on filesystem: ' + this.get('imageUrl'));
            return this.get("imageUrl");
          }),
      ]).then(([thumbnailUrl, imageUrl]) => {
        return {
          onBookshelf: bookshelfData.onBookshelf,
          pdfOnBookshelf: bookshelfData.pdfOnBookshelf,
          downloadComplete: bookshelfData.downloadComplete,
          totalSize: bookshelfData.totalSize,
          thumbnailUrl: thumbnailUrl,
          imageUrl: imageUrl,
        };
      });
    } else {
      return {
        onBookshelf: bookshelfData.onBookshelf,
        pdfOnBookshelf: bookshelfData.pdfOnBookshelf,
        downloadComplete: bookshelfData.downloadComplete,
        totalSize: bookshelfData.totalSize,
      };
    }
  }

  parseHybridData(data) {
    this.set(data);
  }

  isRtl() {
    return this.get("textDirectionality") === "RTL";
  }

  // For now, just grab first 4 awards
  getAwards(badges) {
    return _.slice(_.filter(badges, { type: "award" }), 0, 4);
  }
}

export default ContentModel;

var Handlebars = require('/opt/atlassian/pipelines/agent/build/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"blackMediaBadges") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":20},"end":{"line":53,"column":29}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <div class=\"content-provider-badge content-provider-badge--tile\">\n                            <img class=\"content-provider-badge__img--tile\" src=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"contentProvider") : stack1), depth0))
    + "\">\n                        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, alias5="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--tile-->\n<div class=\"anthology-tile\">\n\n    <!-- header -->\n    <div class=\"tile-header\">\n        <div class=\"tile-header-content-type\">\n            <i class=\"bbico bbico-anthology  m-r-5\"></i>\n            "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"anthology") : stack1)) != null ? lookupProperty(stack1,"singular") : stack1), depth0))
    + "\n        </div>\n\n\n        <div class=\"tile-header-actions\">\n            <div>\n                <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorites") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                        class=\"btn-favorite circle-btn-mini\"\n                        data-toggle=\"tooltip\"\n                        title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToFavorites") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n                    <i class=\"bbico bbico-favorite\"></i>\n                    <i class=\"bbico bbico-favorite-filled\"></i>\n                </button>\n            </div>\n\n            <div>\n                <button aria-label=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1)) != null ? lookupProperty(stack1,"ariaLabel") : stack1), depth0))
    + "\"\n                        class=\"circle-btn-mini btn-curation-add hide\"\n                        data-toggle=\"tooltip\"\n                        title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"addToCuration") : stack1)) != null ? lookupProperty(stack1,"title") : stack1), depth0))
    + "\">\n                    <i class=\"bbico bbico-add\"></i>\n                </button>\n            </div>\n\n        </div>\n\n    </div>\n\n    <div class=\"tile-main-body\">\n        <a href=\"/"
    + alias2(((helper = (helper = lookupProperty(helpers,"typeLabelLowerCase") || (depth0 != null ? lookupProperty(depth0,"typeLabelLowerCase") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"typeLabelLowerCase","hash":{},"data":data,"loc":{"start":{"line":37,"column":18},"end":{"line":37,"column":40}}}) : helper)))
    + "/"
    + alias2(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":37,"column":41},"end":{"line":37,"column":47}}}) : helper)))
    + "\"  class=\"tile-action\">\n\n            <!--image-->\n            <div class=\"media-image-container\">\n                <!--media image-->\n                <img class=\"bb-media-salvattore-tile-image\" src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"thumbnailUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"thumbnailUrl","hash":{},"data":data,"loc":{"start":{"line":42,"column":65},"end":{"line":42,"column":81}}}) : helper)))
    + "\" data-fallback-src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"thumbnailFallbackUrl") || (depth0 != null ? lookupProperty(depth0,"thumbnailFallbackUrl") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"thumbnailFallbackUrl","hash":{},"data":data,"loc":{"start":{"line":42,"column":102},"end":{"line":42,"column":126}}}) : helper)))
    + "\"\n                     alt=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"i18n") : depth0)) != null ? lookupProperty(stack1,"thumbnailAlt") : stack1), depth0))
    + "\">\n            </div>\n\n            <div class=\"tile-banners\">\n                <!--content provider badges-->\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"showBadges") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":16},"end":{"line":54,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n\n\n            <div class=\"title\">\n                <!--title-->\n                <div class=\"h3 truncate-2\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":60,"column":43},"end":{"line":60,"column":51}}}) : helper)))
    + "</div>\n\n                <div class=\"h4 truncate-2\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"subName") || (depth0 != null ? lookupProperty(depth0,"subName") : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"subName","hash":{},"data":data,"loc":{"start":{"line":62,"column":43},"end":{"line":62,"column":54}}}) : helper)))
    + "</div>\n            </div>\n\n        </a>\n    </div>\n</div>\n";
},"useData":true});
import _ from "lodash";
import Backbone from "backbone";
import Controller from "scripts/controllers/controller";
import AnthologyCollectionModel from "scripts/models/anthologyCollectionModel";
import CollectionPageView from "../views/collectionPageView";
import CollectionPageContentSubPageView from "../views/collectionPageContentSubPageView";
import inject from "scripts/ioc/inject";

const chan = Backbone.Radio.channel;

class CollectionController extends Controller {
  constructor(securityService = inject("securityService")) {
    super();

    this.securityService = securityService;
  }

  get route() {
    return "route:collection";
  }

  get defaults() {
    return {
      contentType: "all-content",
      presentationType: "masonry",
    };
  }

  routeAttrs() {
    const user = this.securityService.getUser();

    let pageIndex = parseInt(arguments[3], 10);

    if (!isNaN(pageIndex)) {
      pageIndex = pageIndex - 1;
    } else {
      pageIndex = 0;
    }

    return _.merge(
      {},
      this.defaults,
      {
        presentationType: user.getContentViewPreference(),
      },
      {
        collectionId: arguments[0],
        presentationType: arguments[1] || void 0,
        contentType: arguments[2] || void 0,
        pageIndex,
      },
    );
  }

  replyRoute() {
    const attrs = this.routeAttrs.apply(this, _.tail(arguments));

    const subPageView = new CollectionPageContentSubPageView({
      model: new Backbone.Model({
        collectionId: attrs.collectionId,
        presentationType: attrs.presentationType,
        contentType: attrs.contentType,
        pageIndex: attrs.pageIndex,
      }),
      mediaModel: new AnthologyCollectionModel({
        id: attrs.collectionId,
      }),
    });

    let collectionPageView = chan("controllerView").request("collectionPageView", attrs.collectionId);

    if (collectionPageView) {
      collectionPageView.model.set(attrs);

      return collectionPageView.showSubPageView(subPageView);
    } else {
      collectionPageView = new CollectionPageView({
        model: new Backbone.Model(attrs),
        mediaModel: new AnthologyCollectionModel({
          id: attrs.collectionId,
        }),
        subPageView: subPageView,
      });

      return this.showMainBodyView(collectionPageView);
    }
  }
}

export default CollectionController;

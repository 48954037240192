import i18next from "i18next";

export const buildGlossary = () => ({
  organization: {
    inactive: org =>
      i18next.t("organization.inactive", "organization", "{{org}} is no longer active.", {
        org,
        ns: "glossary",
      }),
    addLibraries: i18next.t("organization.addLibraries", "Add or remove libraries", {
      ns: "glossary",
    }),
    availableLibraries: i18next.t("organization.availableLibraries", "Available Libraries", {
      ns: "glossary",
    }),
    checkLocation: i18next.t("organization.checkLocation", "Check my location for more libraries", { ns: "glossary" }),
    getLibraryCard: org =>
      i18next.t("organization.getLibraryCard", "Get a library card for {{org}}", { org, ns: "glossary" }),
    notFound: i18next.t("organization.notFound", "No library could be found.", {
      ns: "glossary",
    }),
    notYourLibrary: i18next.t("organization.notYourLibrary", "Not your library?", {
      ns: "glossary",
    }),
    singular: i18next.t("organization.singular", "Organization", {
      ns: "glossary",
    }),
    logo: i18next.t("organization", "Organization Logo", { ns: "glossary" }),
    switch: i18next.t("organization.switch", "Switch Library", {
      ns: "glossary",
    }),
  },
  auth: {
    orgMember: i18next.t("{{org}} Member", { ns: "glossary" }),
    biblioHome: i18next.t("auth.biblioHome", "Biblioboard Home", {
      ns: "glossary",
    }),
    geo: {
      checkLocation: i18next.t("Check my location", { ns: "glossary" }),
      check: i18next.t("Check to see if you're in a participating region.", {
        ns: "glossary",
      }),
      publicLibraries: i18next.t(
        "Public libraries in your area may have teamed up to offer BiblioBoard based on where you are.",
        { ns: "glossary" },
      ),
    },
    continueAsGuest: i18next.t("Continue As A Guest", { ns: "glossary" }),
    createYourProfile: i18next.t("auth.createYourProfile", "Create your profile", { ns: "glossary" }),
    noProfile: i18next.t("Don't have a profile?", { ns: "glossary" }),
    email: i18next.t("Email", { ns: "glossary" }),
    emailVerification: i18next.t("auth.emailVerification", "Email Verification", { ns: "glossary" }),
    enjoyDigital: i18next.t(
      "Enjoy digital books, images, videos and more from libraries, artists, authors and others from your community and around the world.",
      { ns: "glossary" },
    ),
    publicAccess: {
      enjoyDigital: i18next.t(
        "Enjoy digital books, images, videos and more from libraries, artists, authors and others from your community and around the world.",
        { ns: "glossary" },
      ),
      enjoyEverything: i18next.t(
        "Enjoy everything in BiblioBoard public, plus content your library has provided its patrons. BiblioBoard Patron offers exciting new fiction, popular comic books, award-winning videos, colorful children's books and much more.",
        { ns: "glossary" },
      ),
      free: i18next.t("Free to members of participating libraries.", {
        ns: "glossary",
      }),
      openToEveryone: i18next.t("Open to everyone! No login required.", {
        ns: "glossary",
      }),
      look: i18next.t("Take a look!", { ns: "glossary" }),
      whichExperience: i18next.t("Which Biblioboard experience is for you?", {
        ns: "glossary",
      }),
    },
    enjoyEverything: i18next.t(
      "Enjoy everything in BiblioBoard Public, plus content your library has provided its patrons.\nBiblioBoard Patron offers exciting new fiction, popular comic books, award-winning videos, colorful children's books and much more.",
      { ns: "glossary" },
    ),
    session: {
      extend: i18next.t("auth.session.extend", "Extend Session", {
        ns: "glossary",
      }),
      existing: i18next.t("auth.session.existing", "Your existing session is about to expire.", {
        ns: "glossary",
      }),
    },
    failed: i18next.t("auth.failed", "Failed", { ns: "glossary" }),
    forgotPassword: {
      question: i18next.t("auth.forgotPassword.question", "Forgot password?", {
        ns: "glossary",
      }),
      forgot: i18next.t("auth.forgotPassword.forgot", "Forgot? We'll send you your login information.", {
        ns: "glossary",
      }),
      remember: i18next.t("auth.forgotPassword.remember", "I remember", {
        ns: "glossary",
      }),
      send: i18next.t("auth.forgotPassword.send", "Send me that email!", {
        ns: "glossary",
      }),
    },
    free: i18next.t("Free to members of participating libraries.", {
      ns: "glossary",
    }),
    getStartedNow: i18next.t("auth.getStartedNow", "Get Started Now", {
      ns: "glossary",
    }),
    startExploring: i18next.t("auth.startExploring", "Log in and start exploring!", {
      ns: "glossary",
    }),
    membership: i18next.t("auth.membership", "membership.", { ns: "glossary" }),
    needHelp: i18next.t("auth.needHelp", "Need help or want feedback?", {
      ns: "glossary",
    }),
    noThanks: i18next.t("auth.noThanks", "No thanks! Continue without creating a profile.", {
      ns: "glossary",
    }),
    open: i18next.t("Open to everyone! No login required.", { ns: "glossary" }),
    pleaseVerify: i18next.t("auth.pleaseVerify", "Please verify your", {
      ns: "glossary",
    }),
    resend: i18next.t("auth.resend", "Resend", { ns: "glossary" }),
    reset: i18next.t("Reset your", { ns: "glossary" }),
    sending: i18next.t("auth.sending", "sending...", { ns: "glossary" }),
    takeALook: i18next.t("Take a look!", { ns: "glossary" }),
    thankYouVerify: i18next.t("auth.thankYouVerify", "Thank you for verifying your email address.", {
      ns: "glossary",
    }),
    problem: i18next.t("auth.problem", "There was a problem verifying your email address.", {
      ns: "glossary",
    }),
    unlock: i18next.t("auth.unlock", "Unlock these great features by creating your profile:", {
      ns: "glossary",
    }),
    verification: i18next.t("auth.verification", "Verification", {
      ns: "glossary",
    }),
    verified: i18next.t("auth.verified", "Verified", { ns: "glossary" }),
    viewPrivacyPolicy: i18next.t("auth.viewPrivacyPolicy", "View our Privacy Policy", { ns: "glossary" }),
    welcome: i18next.t("Welcome", { ns: "glossary" }),
    whichExperience: i18next.t("Which BiblioBoard experience is for you?", {
      ns: "glossary",
    }),
    aboutToView: i18next.t("You're about to view:", { ns: "glossary" }),
    yourVerifiedEmail: i18next.t("auth.yourVerifiedEmail", "Your verified email address is:", {
      ns: "glossary",
    }),
  },
  viewer: {
    book: {
      aChapter: i18next.t("A chapter from the book", { ns: "glossary" }),
      note: {
        add: i18next.t("viewer.book.note.add", "Add Note", { ns: "glossary" }),
        edit: i18next.t("viewer.book.note.edit", "Edit Note", {
          ns: "glossary",
        }),
        new: i18next.t("viewer.book.note.new", "New Note", { ns: "glossary" }),
        singular: i18next.t("viewer.book.note.singular", "Note", {
          ns: "glossary",
        }),
        plural: i18next.t("viewer.book.note.plural", "Notes", {
          ns: "glossary",
        }),
        optional: i18next.t("viewer.book.note.optional", "optional", {
          ns: "glossary",
        }),
        title: i18next.t("viewer.book.note.title", "Title", { ns: "glossary" }),
      },
      calculating: i18next.t("viewer.book.calculating", "Calculating...", {
        ns: "glossary",
      }),
      chapter: i18next.t("viewer.book.chapter", "chapter", { ns: "glossary" }),
      menu: {
        close: i18next.t("Close Menu", { ns: "glossary" }),
        open: i18next.t("Open Menu", { ns: "glossary" }),
      },
      complete: i18next.t("viewer.book.complete", "complete", {
        ns: "glossary",
      }),
      global: i18next.t("Global", { ns: "glossary" }),
      makeNote: i18next.t("Make a note", { ns: "glossary" }),
      chapterNext: i18next.t("viewer.book.chapterNext", "next chapter", {
        ns: "glossary",
      }),
      of: i18next.t("viewer.book.of", "of", { ns: "glossary" }),
      chapterPrevious: i18next.t("viewer.book.chapterPrevious", "previous chapter", { ns: "glossary" }),
      results: i18next.t("viewer.book.results", "Results", { ns: "glossary" }),
      search: i18next.t("viewer.book.search", "Search", { ns: "glossary" }),
      searchChapter: i18next.t("viewer.book.searchChapter", "Search Chapter", {
        ns: "glossary",
      }),
      selectNoteType: i18next.t("viewer.book.selectNoteType", "Select Note Type", {
        ns: "glossary",
      }),
      seeDetails: i18next.t("See Book Details", { ns: "glossary" }),
      seeDetailsFull: i18next.t("See Full Details", { ns: "glossary" }),
      thisPage: i18next.t("viewer.book.thisPage", "This Page", {
        ns: "glossary",
      }),
    },
    epub: {
      font: i18next.t("viewer.epub.font", "Book Font", { ns: "glossary" }),
      defaultFont: i18next.t("viewer.epub.defaultFont", "Default", {
        ns: "glossary",
      }),
      bookmarks: i18next.t("viewer.epub.bookmarks", "Bookmarks", {
        ns: "glossary",
      }),
      note: {
        plural: i18next.t("viewer.epub.note.plural", "Notes", {
          ns: "glossary",
        }),
      },
    },
    imageBook: {
      bookmarks: i18next.t("viewer.imageBook.bookmarks", "Bookmarks", {
        ns: "glossary",
      }),
      notes: i18next.t("viewer.imageBook.notes", "Notes", { ns: "glossary" }),
    },
    image: {
      relatedImages: i18next.t("viewer.image.relatedImages", "Related Images", {
        ns: "glossary",
      }),
    },
  },
  app: {
    newVersion: i18next.t("app.newVersion", "A new version of Biblioboard is available!", {
      ns: "glossary",
    }),
    diagnostics: {
      browser: i18next.t("app.diagnostics.browser", "Browser", {
        ns: "glossary",
      }),
      button: {
        copy: i18next.t("app.diagnostics.button.copy", "Copy Diagnostics", {
          ns: "glossary",
        }),
        email: i18next.t("app.diagnostics.button.email", "Email Diagnostics to Support", {
          ns: "glossary",
        }),
      },
      date: i18next.t("app.diagnostics.date", "Date", { ns: "glossary" }),
      plural: i18next.t("app.diagnostics.plural", "Diagnostics", {
        ns: "glossary",
      }),
      geoTime: i18next.t("app.diagnostics.geoTime", "Geo Lat. / Long. (Time ms)", {
        ns: "glossary",
      }),
      geolocation: i18next.t("app.diagnostics.geolocation", "Geolocation", {
        ns: "glossary",
      }),
      ip: {
        address: i18next.t("app.diagnostics.ip.address", "IP Address", {
          ns: "glossary",
        }),
        coordinates: i18next.t("app.diagnostics.ip.coordinates", "IP Lat. / Long.", {
          ns: "glossary",
        }),
        region: i18next.t("app.diagnostics.ip.region", "IP Region", {
          ns: "glossary",
        }),
      },
      updateLocation: i18next.t("app.diagnostics.updateLocation", "Update Location", {
        ns: "glossary",
      }),
    },
    support: i18next.t("app.support", "Contact Support", { ns: "glossary" }),
    goThereNow: i18next.t("Go There Now", { ns: "glossary" }),
    oneMoment: i18next.t("app.oneMoment", "one moment...", { ns: "glossary" }),
    pleaseVisit: i18next.t("app.pleaseVisit", "Please visit", {
      ns: "glossary",
    }),
    poweredBy: i18next.t("app.poweredBy", "POWERED BY", { ns: "glossary" }),
    readBlog: i18next.t("Read Our Blog", { ns: "glossary" }),
    sorry: i18next.t("app.sorry", "Sorry!", { ns: "glossary" }),
    eBook: i18next.t("Submit Your eBook", { ns: "glossary" }),
  },
  search: {
    sortBy: {
      name: {
        asc: i18next.t("search.sortBy.name.asc", "A-Z", { ns: "glossary" }),
        desc: i18next.t("search.sortBy.name.desc", "Z-A", { ns: "glossary" }),
      },
      publicationDate: {
        desc: i18next.t("search.sortBy.publicationDate.desc", "Publication Date (New - Old)", {
          ns: "glossary",
        }),
        asc: i18next.t("search.sortBy.publicationDate.asc", "Publication Date (Old - New)", { ns: "glossary" }),
      },
      updated: {
        desc: i18next.t("search.sortBy.updated.desc", "Last Updated", {
          ns: "glossary",
        }),
      },
      relevance: i18next.t("search.sortBy.relevance", "Relevance", {
        ns: "glossary",
      }),
      sortBy: i18next.t("search.sortBy.sortBy", "Sort By", { ns: "glossary" }),
    },
    category: {
      browse: i18next.t("search.category.browse", "Browse By Category", {
        ns: "glossary",
      }),
      plural: i18next.t("search.category.plural", "Categories", {
        ns: "glossary",
      }),
      singular: i18next.t("search.category.singular", "Category", {
        ns: "glossary",
      }),
    },
    contentType: i18next.t("search.contentType", "Content Type", {
      ns: "glossary",
    }),
    contributor: i18next.t("search.contributor", "Contributor", {
      ns: "glossary",
    }),
    filter: {
      plural: i18next.t("search.filter.plural", "Filters", { ns: "glossary" }),
      for: i18next.t("search.filter.for", "for", { ns: "glossary" }),
    },
    fullText: i18next.t("search.fullText", "Full Text", { ns: "glossary" }),
    item: {
      singular: i18next.t("search.item.singular", "item", { ns: "glossary" }),
      plural: i18next.t("search.item.plural", "items", { ns: "glossary" }),
    },
    language: i18next.t("search.language", "Language", { ns: "glossary" }),
    licenseType: i18next.t("search.licenseType", "License Type", {
      ns: "glossary",
    }),
    module: i18next.t("search.module", "Module", { ns: "glossary" }),
    placeOfPublication: i18next.t("search.placeOfPublication", "Place of Publication", { ns: "glossary" }),
    publicationYear: i18next.t("search.publicationYear", "Publication Year", {
      ns: "glossary",
    }),
    publisher: i18next.t("search.publisher", "Publisher", { ns: "glossary" }),
    search: {
      singular: i18next.t("search.search.singular", "Search", {
        ns: "glossary",
      }),
    },
    titleOnly: i18next.t("search.titleOnly", "Title Only", { ns: "glossary" }),
  },
  footer: {
    accessibility: i18next.t("footer.accessibility", "Accessibility", {
      ns: "glossary",
    }),
    allRightsReserved: i18next.t("footer.allRightsReserved", "All Rights Reserved.", {
      ns: "glossary",
    }),
    blog: i18next.t("footer.blog", "Blog", { ns: "glossary" }),
    contribute: i18next.t("footer.contribute", "Contribute", {
      ns: "glossary",
    }),
    cookieSettings: i18next.t("footer.cookieSettings", "Cookie Settings", {
      ns: "glossary",
    }),
    ebook: {
      create: i18next.t("footer.ebook.create", "Create Your eBook", {
        ns: "glossary",
      }),
      share: i18next.t("footer.ebook.share", "Share Your eBook", {
        ns: "glossary",
      }),
    },
    downloadApp: i18next.t("footer.downloadApp", "Download the app", {
      ns: "glossary",
    }),
    forAssistance: i18next.t("footer.forAssistance", "For assistance or to learn more about", {
      ns: "glossary",
    }),
    forAuthors: i18next.t("footer.forAuthors", "For Authors", {
      ns: "glossary",
    }),
    forLibraries: i18next.t("footer.forLibraries", "For Libraries", {
      ns: "glossary",
    }),
    libraryResources: i18next.t("footer.libraryResources", "Library Resources", {
      ns: "glossary",
    }),
    policy: {
      plural: i18next.t("footer.policy.plural", "Policies", { ns: "glossary" }),
      privacy: i18next.t("footer.policy.privacy", "Privacy Policy", {
        ns: "glossary",
      }),
    },
  },
  curation: {
    addContentToCuration: i18next.t("curation.addContentToCuration", "Add Content To Curation", {
      ns: "glossary",
    }),
    modal: {
      addToCuration: i18next.t("curation.modal.addToCuration", "Add to a Curation", { ns: "glossary" }),
    },
    addToCuration: i18next.t("curation.addToCuration", "Add To Curation", {
      ns: "glossary",
    }),
    all: i18next.t("curation.all", "All", { ns: "glossary" }),
    chooseThumbnail: i18next.t("curation.chooseThumbnail", "Choose a cover image for", { ns: "glossary" }),
    contentAdded: i18next.t("curation.contentAdded", "Content Added to Curation", { ns: "glossary" }),
    button: {
      createNew: i18next.t("curation.button.createNew", "Create a new curation", { ns: "glossary" }),
      explore: i18next.t("curation.button.explore", "explore this curation", {
        ns: "glossary",
      }),
      preview: i18next.t("curation.button.preview", "Preview more from this curation", { ns: "glossary" }),
      select: i18next.t("curation.button.select", "Select For Curation", {
        ns: "glossary",
      }),
      showLess: i18next.t("curation.button.showLess", "Show less from this curation", { ns: "glossary" }),
    },
    singular: i18next.t("curation.singular", "Curation", { ns: "glossary" }),
    plural: i18next.t("curation.plural", "Curations", { ns: "glossary" }),
    draft: i18next.t("curation.draft", "Draft", { ns: "glossary" }),
    filterBy: i18next.t("curation.filterBy", "Filter By", { ns: "glossary" }),
    live: i18next.t("curation.live", "Live", { ns: "glossary" }),
    returnToList: i18next.t("curation.returnToList", "Return to curation list", { ns: "glossary" }),
    edit: i18next.t("curation.edit", "To <b>edit</b> curations, click anywhere on the tile.", {
      ns: "glossary",
    }),
    reorderContent: i18next.t("curation.reorderContent", "To reorder content, drag and drop the cards below.", {
      ns: "glossary",
    }),
    reorder: i18next.t("curation.reorder", "To <b>reorder</b> curations, click and drag the tiles you wish to adjust", {
      ns: "glossary",
    }),
    upload: i18next.t("curation.upload", "Upload a new image", {
      ns: "glossary",
    }),
    viewAll: i18next.t("curation.viewAll", "View all curations", {
      ns: "glossary",
    }),
  },
  menu: {
    addNotes: i18next.t("menu.addNotes", "Add Notes", { ns: "glossary" }),
    bookmarks: i18next.t("menu.bookmarks", "Bookmarks", { ns: "glossary" }),
    createBookmarks: i18next.t("menu.createBookmarks", "Create Bookmarks", {
      ns: "glossary",
    }),
    createYourBook: i18next.t("menu.createYourBook", "Create your book", {
      ns: "glossary",
    }),
    createProfile: {
      optional: i18next.t("menu.createProfile.optional", "Create your optional profile", { ns: "glossary" }),
      unlockFeatures: i18next.t("menu.createProfile.unlockFeatures", "to unlock these great features:", {
        ns: "glossary",
      }),
    },
    createYoursNow: i18next.t("menu.createYoursNow", "Create Yours Now!", {
      ns: "glossary",
    }),
    dontHaveProfile: i18next.t("menu.dontHaveProfile", "Don't have a profile?", { ns: "glossary" }),
    favorites: i18next.t("menu.favorites", "Favorites", { ns: "glossary" }),
    for: {
      authors: i18next.t("menu.for.authors", "For Authors", { ns: "glossary" }),
      institutions: i18next.t("menu.for.institutions", "For Institutions", {
        ns: "glossary",
      }),
      publishers: i18next.t("menu.for.publishers", "For Publishers", {
        ns: "glossary",
      }),
    },
    metadata: i18next.t("menu.metadata", "Get metadata", { ns: "glossary" }),
    haveProfile: i18next.t("menu.haveProfile", "If you have a profile,", {
      ns: "glossary",
    }),
    learnAboutIAP: i18next.t("menu.learnAboutIAP", "Learn about the Indie Author Project", {
      ns: "glossary",
    }),
    manageCurations: i18next.t("menu.manageCurations", "Manage Curations", {
      ns: "glossary",
    }),
    myBoard: i18next.t("menu.myBoard", "My Board", { ns: "glossary" }),
    offlineBookshelf: i18next.t("menu.offlineBookshelf", "Offline Bookshelf", {
      ns: "glossary",
    }),
    openAccessAuthors: i18next.t("menu.openAccessAuthors", "Open Access Author Interviews", {
      ns: "glossary",
    }),
    participate: i18next.t("menu.participate", "Participate", {
      ns: "glossary",
    }),
    pledgeSupport: i18next.t("menu.pledgeSupport", "Pledge Support", {
      ns: "glossary",
    }),
    recents: i18next.t("menu.recents", "Recents", { ns: "glossary" }),
    saveFavorites: i18next.t("menu.saveFavorites", "Save Favorites", {
      ns: "glossary",
    }),
    shareYourBook: i18next.t("menu.shareYourBook", "Share your book", {
      ns: "glossary",
    }),
    singular: i18next.t("menu.singular", "Menu", { ns: "glossary" }),
    syncPreferences: i18next.t("menu.syncPreferences", "Sync Preferences", {
      ns: "glossary",
    }),
  },
  content: {
    all: i18next.t("content.all", "All", { ns: "glossary" }),
    allCount: count =>
      i18next.t("content.allCount", "All ({{count}})", {
        count,
        ns: "glossary",
      }),
    filter: {
      all: i18next.t("content.filter.all", "All", { ns: "glossary" }),
      global: i18next.t("content.filter.global", "Global", { ns: "glossary" }),
      page: i18next.t("content.filter.page", "Page", { ns: "glossary" }),
    },
    details: {
      ageRange: i18next.t("content.details.ageRange", "Age Range", {
        ns: "glossary",
      }),
      alsoFoundIn: i18next.t("content.details.alsoFoundIn", "Also Found In", {
        ns: "glossary",
      }),
      attribution: i18next.t("content.details.attribution", "Attribution", {
        ns: "glossary",
      }),
      audience: i18next.t("content.details.audience", "Audience", {
        ns: "glossary",
      }),
      audioType: i18next.t("content.details.audioType", "Audio Type", {
        ns: "glossary",
      }),
      category: i18next.t("content.details.category", "Category", {
        ns: "glossary",
      }),
      citations: i18next.t("content.details.citations", "Citations", {
        ns: "glossary",
      }),
      collectionInfo: i18next.t("content.details.collectionInfo", "Collection Information", {
        ns: "glossary",
      }),
      comments: i18next.t("content.details.comments", "Comments", {
        ns: "glossary",
      }),
      description: i18next.t("content.details.description", "Description", {
        ns: "glossary",
      }),
      plural: i18next.t("content.details.plural", "Details", {
        ns: "glossary",
      }),
      doi: i18next.t("content.details.doi", "DOI", { ns: "glossary" }),
      episode: i18next.t("content.details.episode", "Episode", { ns: "glossary" }),
      funder: i18next.t("content.details.funder", "Funder", { ns: "glossary" }),
      isbn: {
        singular: i18next.t("content.details.isbn.singular", "ISBN", {
          ns: "glossary",
        }),
        plural: i18next.t("content.details.isbn.plural", "ISBN(S)", {
          ns: "glossary",
        }),
      },
      issn: i18next.t("content.details.issn", "ISSN", { ns: "glossary" }),
      keywords: i18next.t("content.details.keywords", "Keywords", {
        ns: "glossary",
      }),
      language: i18next.t("content.details.language", "Language", {
        ns: "glossary",
      }),
      licenseType: i18next.t("content.details.licenseType", "License Type", {
        ns: "glossary",
      }),
      note: {
        add: i18next.t("content.details.note.add", "Add Note", {
          ns: "glossary",
        }),
        addFirst: i18next.t("content.details.note.addFirst", "Add First Note", {
          ns: "glossary",
        }),
        singular: i18next.t("content.details.note.singular", "Note", {
          ns: "glossary",
        }),
        plural: i18next.t("content.details.note.plural", "Notes", {
          ns: "glossary",
        }),
        optional: i18next.t("content.details.note.optional", "optional", {
          ns: "glossary",
        }),
        title: i18next.t("content.details.note.title", "Title", {
          ns: "glossary",
        }),
      },
      optional: i18next.t("content.details.optional", "optional", {
        ns: "glossary",
      }),
      pageRange: i18next.t("content.details.pageRange", "Page Range", {
        ns: "glossary",
      }),
      periodical: i18next.t("content.details.periodical", "Periodical", {
        ns: "glossary",
      }),
      placeOfPublication: i18next.t("content.details.placeOfPublication", "Place of Publication", {
        ns: "glossary",
      }),
      publicationDate: i18next.t("content.deatils.publicationDate", "Publication Date", {
        ns: "glossary",
      }),
      publisher: i18next.t("content.details.publisher", "Publisher", {
        ns: "glossary",
      }),
      raveLink: i18next.t("content.details.raveLink", "Rave Link", {
        ns: "glossary",
      }),
      scanSource: i18next.t("content.details.scanSource", "Scan Source", {
        ns: "glossary",
      }),
      series: i18next.t("content.details.series", "Series", { ns: "glossary" }),
      title: i18next.t("content.details.title", "Title", { ns: "glossary" }),
      tracks: i18next.t("content.details.tracks", "Tracks", { ns: "glossary" }),
    },
    article: {
      singular: i18next.t("content.article.singular", "Article", {
        ns: "glossary",
      }),
      plural: i18next.t("content.article.plural", "Articles", {
        ns: "glossary",
      }),
      count: count =>
        i18next.t("content.article.count", "Articles ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    album: {
      singular: i18next.t("content.album.singular", "Album", {
        ns: "glossary",
      }),
      plural: i18next.t("content.album.plural", "Albums", {
        ns: "glossary",
      }),
      count: count =>
        i18next.t("content.album.count", "Albums ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    audio: {
      singular: i18next.t("content.audio.singular", "Audio", {
        ns: "glossary",
      }),
      count: count =>
        i18next.t("content.audio.count", "Audio ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    audiobook: {
      singular: i18next.t("content.audiobook.singular", "Audiobook", {
        ns: "glossary",
      }),
      plural: i18next.t("content.audiobook.plural", "Audiobooks", {
        ns: "glossary",
      }),
      count: count =>
        i18next.t("content.audiobook.count", "Audiobooks ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    book: {
      singular: i18next.t("content.book.singular", "Book", { ns: "glossary" }),
      plural: i18next.t("content.book.plural", "Books", { ns: "glossary" }),
      count: count =>
        i18next.t("content.book.count", "Books ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    chapter: {
      singular: i18next.t("content.chapter.singular", "Chapter", {
        ns: "glossary",
      }),
      plural: i18next.t("content.chapter.plural", "Chapters", {
        ns: "glossary",
      }),
      count: count =>
        i18next.t("content.chapter.count", "Chapters {{count}}", {
          count,
          ns: "glossary",
        }),
    },
    singular: i18next.t("content.singular", "Content", { ns: "glossary" }),
    plural: i18next.t("content.plural", "Contents", { ns: "glossary" }),
    document: {
      singular: i18next.t("content.document.singular", "Document", {
        ns: "glossary",
      }),
      plural: i18next.t("content.document.plural", "Documents", {
        ns: "glossary",
      }),
      count: count =>
        i18next.t("content.document.count", "Documents ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    ed: number => i18next.t("content.ed", "Ed. {{number}}", { number, ns: "glossary" }),
    highlight: i18next.t("content.highlight", "Highlight", { ns: "glossary" }),
    highlights: i18next.t("content.highlights", "Highlights", {
      ns: "glossary",
    }),
    highlightsCount: count =>
      i18next.t("content.highlightsCount", "Highlights ({{count}})", {
        count,
        ns: "glossary",
      }),
    image: {
      singular: i18next.t("content.image.singular", "Image", {
        ns: "glossary",
      }),
      plural: i18next.t("content.image.plural", "Images", { ns: "glossary" }),
      count: count =>
        i18next.t("content.image.count", "Images ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    languageContent: i18next.t("content.languageContent", "Language Content", {
      ns: "glossary",
    }),
    no: number => i18next.t("content.no", "No. {{number}}", { number, ns: "glossary" }),
    onDevice: i18next.t("content.onDevice", "on device", { ns: "glossary" }),
    page: i18next.t("content.page", "Page", { ns: "glossary" }),
    textbook: {
      singular: i18next.t("content.textbook.singular", "Textbook", {
        ns: "glossary",
      }),
      plural: i18next.t("content.textbook.plural", "Textbooks", {
        ns: "glossary",
      }),
      count: count =>
        i18next.t("content.textbook.count", "Textbooks ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    video: {
      singular: i18next.t("content.video.singular", "Video", {
        ns: "glossary",
      }),
      plural: i18next.t("content.video.plural", "Videos", { ns: "glossary" }),
      count: count =>
        i18next.t("content.video.count", "Videos ({{count}})", {
          count,
          ns: "glossary",
        }),
    },
    vol: number => i18next.t("content.vol", "Vol. {{number}}", { number, ns: "glossary" }),
    written: i18next.t("Written", { ns: "glossary" }),
  },
  metadata: {
    orl: {
      license: i18next.t("metadata.orl.license", "All metadata feeds are available under a CC0 1.0 license.", {
        ns: "glossary",
      }),
      header: i18next.t("metadata.orl.header", "Open Research Library Metadata", { ns: "glossary" }),
      body: i18next.t(
        "metadata.orl.body",
        "Open Research Library metadata is available through ProQuest (Summon and Primo Central), EBSCO (EBSCO Discovery Service), and OCLC (Worldcat Knowledge Base).",
        { ns: "glossary" },
      ),
    },
    melsa: {
      header: i18next.t("metadata.melsa.header", "MN Writes, MN Reads Metadata", { ns: "glossary" }),
    },
  },
  disqus: {
    loadingError: i18next.t("disqus.loadingError", "An error has occurred while loading the comments.", {
      ns: "glossary",
    }),
    displayName: i18next.t("disqus.displayName", "Display Name", {
      ns: "glossary",
    }),
    flagComment: {
      header: i18next.t("disqus.flagComment.header", "Flag Comment", {
        ns: "glossary",
      }),
      body: i18next.t("disqus.flagComment.body", "This comment has been flagged.", { ns: "glossary" }),
    },
    loadingComments: i18next.t("disqus.loadingComments", "Loading Comments", {
      ns: "glossary",
    }),
  },
  anthology: {
    count: count =>
      i18next.t("anthology.count", "Anthologies ({{count}})", {
        count,
        ns: "glossary",
      }),
    plural: i18next.t("anthology.plural", "Anthologies", { ns: "glossary" }),
    singular: i18next.t("anthology.singular", "Anthology", { ns: "glossary" }),
    included: i18next.t("anthology.included", "Included in the Anthology:", {
      ns: "glossary",
    }),
    overview: i18next.t("anthology.overview", "Overview", { ns: "glossary" }),
  },
  legal: {
    cookies: {
      biblioPolicy: {
        header: "BiblioBoard Cookie Policy",
        body: 'This Cookie Policy describes what cookies and similar technologies are used on BiblioLabs & BiblioBoard websites and apps ("Biblioboard services"), why they are used, and how you can control their use.',
      },
      types: {
        body: "BiblioBoard services may use the following types of cookies:",
        header: "What types of Cookies does BiblioBoard use?",
      },
      biblioUseCookies: {
        body: "BiblioBoard uses cookies for many purposes, such as to support the internal operations of the system, make improvements, help libraries understand how users are interacting with BiblioBoard, and to improve the user experience.",
        examples: {
          third: {
            example: "COUNTER and other anonymized reporting.",
            explanation:
              "Libraries receive anonymized reports so they can better serve their patrons through additional programming and content purchases.",
          },
          second: {
            explanation:
              "In order to maintain and improve an exceptional patron experience, the BiblioBoard system is routinely monitored for potential problems. Some methods of monitoring may require the use of a cookie.",
            example: "Monitoring performance-related issues.",
          },
          first: {
            explanation:
              "Preferences such as the font used in the reader or whether to allow analytics are stored in a cookie.",
            example: "User preferences.",
          },
          header: "Some examples of how we use cookies include:",
        },
        header: "How does BiblioBoard use cookies?",
      },
      required: {
        body: "BiblioBoard uses first party cookies (i.e. cookies set directly by BiblioBoard to your device) that are strictly necessary. These are essential cookies that let you navigate, acces, and use the features of BiblioBoard services. Without these cookies, core functionality cannot be provided. You cannot object to these cookies, as otherwise BiblioBoard services cannot be provided.",
        header: "Required cookies:",
      },
      policy: "Cookie Policy",
      definition: {
        body: "Cookies are small data file identifiers that are transferred to your device or web browser. They allow BiblioBoard to recognize your device or web browser when you visit or use BiblioBoard services.",
        header: "What are Cookies?",
      },
      thirdParty: {
        header: "Does BiblioBoard use third party cookies?",
        body1: "Some parts of BiblioBoard may use third party cookies.",
        body2:
          "Specifically, BiblioBoard may use {{googleAnalytics}} for research and reporting information. We use this information to provide libraries with information on how patrons are using the resource and to learn more about how users are interacting with BiblioBoard. BiblioLabs does not use Google Ad services.",
      },
      questions: "If you have any questions regarding this Cookie Policy, please contact BiblioLabs via email at",
      research: {
        header: "Research and Analytics Cookies",
        body: "These cookies help BiblioLabs understand user behavior within the system. For example,\nthese cookies collect information about which features or collections of BiblioBoard are most popular. This information helps us design a better experience for all users.",
      },
      manageCookies: {
        body: "This Cookie Policy was last updated in March 2021. BiblioLabs will continue to evaluate this Cookie Policy against new technologies, business practices, and its users' needs. Changes to the Cookie Policy will be made accordingly. Please check back periodically for updates.",
        header: "What if you do not want cookies to be set?",
      },
    },
  },
  cookies: {
    modal: {
      settings: {
        body: i18next.t(
          "cookies.modal.settings.body",
          "BiblioBoard uses cookies and similar technologies to improve your experience, monitor the system’s performance, and understand overall usage trends for BiblioBoard services (including BiblioLabs websites and apps). We use this information to create a better experience for all users. Please review the types of cookies we use below.",
          { ns: "glossary" },
        ),
        button: i18next.t("cookies.modal.settings.button", "Cookie Settings", {
          ns: "glossary",
        }),
      },
      confirm: {
        button: i18next.t("cookies.modal.confirm.button", "Confirm Cookies Settings", {
          ns: "glossary",
        }),
      },
      cookiePolicy: {
        link: i18next.t("cookies.modal.cookiePolicy.link", "cookie policy", {
          ns: "glossary",
        }),
        text: i18next.t("cookies.modal.cookiePolicy.text", "To learn more about cookies, please see our", {
          ns: "glossary",
        }),
      },
      required: {
        button: i18next.t("cookies.modal.required.button", "Required Cookies", {
          ns: "glossary",
        }),
        body: i18next.t(
          "cookies.modal.required.body",
          "These cookies allow you to explore BiblioBoard services and use our core features. Without these cookies, we can't provide BiblioBoard services to you.",
          { ns: "glossary" },
        ),
      },
      research: {
        button: i18next.t("cookies.modal.research.button", "Research and Analytics Cookies", {
          ns: "glossary",
        }),
        body: i18next.t(
          "cookies.modal.research.body",
          "These cookies help us understand user behavior within our services. For example, they let us know which features and collections are most popular. This information helps us design a better experience for all users.",
          { ns: "glossary" },
        ),
      },
    },
  },
  myBoard: {
    bookmarks: i18next.t("Bookmarks", { ns: "glossary" }),
    favorites: i18next.t("Favorites", { ns: "glossary" }),
    offlineBookshelf: i18next.t("Offline Bookshelf", { ns: "glossary" }),
    recents: i18next.t("Recents", { ns: "glossary" }),
  },
  errorPage: {
    403: {
      halt: i18next.t("errorPage.403.halt", "Halt! Who goes there?", {
        ns: "glossary",
      }),
      noAccess: i18next.t(
        "errorPage.403.noAccess",
        "We know you reeeeallllly want to see this content but unfortunately, you don’t currently have access to it.",
        { ns: "glossary" },
      ),
    },
    404: {
      lost: i18next.t("errorPage.404.lost", "Hi, I'm a little lost.", {
        ns: "glossary",
      }),
      notFound: i18next.t(
        "errorPage.404.notFound",
        "I may have fallen off the shelf or went missing in the return box. Either way, I can't be found right now, but not all hope is lost!",
        { ns: "glossary" },
      ),
    },
    500: {
      whatHappened: i18next.t("errorPage.500.whatHappened", "Oh no, what happened?!", { ns: "glossary" }),
      notSure: i18next.t(
        "errorPage.500.notSure",
        "We're not sure where things went wrong and how we got here, but we sure do hope things get fixed soon!",
        { ns: "glossary" },
      ),
    },
    999: {
      cantTouchThis: i18next.t("errorPage.999.cantTouchThis", "Can't touch this (oh-oh oh oh-oh-oh)", {
        ns: "glossary",
      }),
      forReal: i18next.t(
        "errorPage.999.forReal",
        "For real, though. It looks like the organization associated with this account has an improperly configured subscription or one that has expired.",
        { ns: "glossary" },
      ),
      subscriptionError: i18next.t("errorPage.999.subscriptionError", "Subscription Error", { ns: "glossary" }),
      talkToLibrarian: i18next.t(
        "errorPage.999.talkToLibrarian",
        "Talk to your librarian about renewing their subscription, or contact support for further assistance",
        { ns: "glossary" },
      ),
    },
  },
  collection: {
    singular: i18next.t("collection.singular", "Collection", {
      ns: "glossary",
    }),
    plural: i18next.t("collection.plural", "Collections", { ns: "glossary" }),
    count: count =>
      i18next.t("collection.count", "Collections ({{count}})", {
        count,
        ns: "glossary",
      }),
  },
  comment: {
    delete: i18next.t("comment.delete", "Delete Comment", { ns: "glossary" }),
    flag: i18next.t("comment.flag", "Flag Comment", { ns: "glossary" }),
  },
  rating: {
    disappointed: i18next.t("rating.disappointed", "Disappointed.", {
      ns: "glossary",
    }),
    howManyStars: title =>
      i18next.t("rating.howManyStars", 'How many stars for &ldquo;<span class="fnt-bld">{{title}}</span>&rdquo;?', {
        title,
        ns: "glossary",
      }),
    ok: i18next.t("rating.ok", "It was ok.", { ns: "glossary" }),
    liked: i18next.t("rating.liked", "Liked it.", { ns: "glossary" }),
    loved: i18next.t("rating.loved", "Loved it!", { ns: "glossary" }),
    notFan: i18next.t("rating.notFan", "Not a fan.", { ns: "glossary" }),
    outOf: i18next.t("rating.outOf", "out of", { ns: "glossary" }),
    rateIt: i18next.t("rating.rateIt", "Rate it!", { ns: "glossary" }),
    stars: i18next.t("rating.stars", "stars", { ns: "glossary" }),
    previous: i18next.t("rating.previous", "You previously rated this", {
      ns: "glossary",
    }),
  },
  module: {
    singular: i18next.t("module.singular", "Module", { ns: "glossary" }),
    plural: i18next.t("module.plural", "Modules", { ns: "glossary" }),
    count: count =>
      i18next.t("module.count", "Modules ({{count}})", {
        count,
        ns: "glossary",
      }),
  },
  pager: {
    next: i18next.t("pager.next", "Next", { ns: "glossary" }),
    prev: i18next.t("pager.prev", "Prev", { ns: "glossary" }),
  },
  profile: {
    create: {
      whyEmail: {
        body: i18next.t(
          "profile.create.whyEmail.body",
          "Providing an email address allows us to send you login instructions in the event you forget your username and/or password",
          { ns: "glossary" },
        ),
        header: i18next.t("profile.create.whyEmail.header", "provide an email address?", {
          ns: "glossary",
        }),
      },
      password: {
        validation: i18next.t(
          "profile.create.password.validation",
          "Your password must be at least 8 characters and contain a number",
          { ns: "glossary" },
        ),
      },
    },
  },
  sortBy: {
    date: {
      new: i18next.t("sortBy.date.new", "Sort by date (newest)", {
        ns: "glossary",
      }),
      oldest: i18next.t("sortBy.date.oldest", "Sort by date (oldest)", {
        ns: "glossary",
      }),
    },
    page: {
      highest: i18next.t("sortBy.page.highest", "Sort by page (highest)", {
        ns: "glossary",
      }),
      lowest: i18next.t("sortBy.page.lowest", "Sort by page (lowest)", {
        ns: "glossary",
      }),
    },
  },
  project: {
    submit: i18next.t("project.submit", "Submit to Project", {
      ns: "glossary",
    }),
    contribute: i18next.t(
      "project.contribute",
      'To contribute pieces to this collection, click the "Submit to Project" button to the left or visit',
      { ns: "glossary" },
    ),
  },
});

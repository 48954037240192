import Controller from "scripts/controllers/controller";
import RefreshSessionAlertView from "scripts/views/refreshSessionAlertView";

const chan = Backbone.Radio.channel;

class RefreshSessionController extends Controller {
  constructor() {
    super();

    this.startRefreshTimer();

    chan("security").on("fetch", this.startRefreshTimer, this);
  }

  startRefreshTimer(delay = 3000000) {
    // default is 50 minutes

    clearTimeout(this.timeoutHandle);

    this.timeoutHandle = setTimeout(() => {
      this.showUserUpdateMsg();
    }, delay);
  }

  showUserUpdateMsg() {
    new RefreshSessionAlertView()
      .appendTo($("#bb-app-growl-alert-region"))
      .render();
  }
}

export default RefreshSessionController;
